<template>
  <div class="relative h-full min-h-screen bg-gray-400">
    <div class="pt-10 px-10 flex justify-center align-middle">
      <img :width="50" :height="50" src="./assets/logo.png" />
      <p class="text-4xl pt-3 px-1 mt-1 text-black">SKULL</p>
      <p class="text-4xl pt-3 px-2 mt-1 text-black">INVASION</p>
      <img :width="50" :height="50" src="./assets/logo.png" />
    </div>  

    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
export default {
  components: { TheCat, TheFooter, TheNavBar },
};
</script>

<style>
* {
  font-family: 'Press Start 2P', monospace;
}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
